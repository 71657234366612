import React, { useEffect } from 'react'
import { navigate } from 'gatsby';

const Press = () => {
  useEffect(() => {
    navigate('/press/news');
  }, []);

  return null;
}

export default Press;